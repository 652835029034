<template>
    <v-layout column="" row wrap pr-3 mr-2 mt-1>

        <v-row>

            <v-data-table :headers="headers" :items="ownres" sort-by="calories" class="elevation-1 request_table"
                style="width:96%" :items-per-page="25" :search="search" hide-default-footer="" :loading="loading"
                loading-text="جاري تحميل البيانات">



                <template v-slot:top>
                    <v-toolbar flat color="white" pb-5>

                        <v-toolbar-title>
                            <!-- <v-btn text style="font-size:22px;" @click="all()">  التجار</v-btn>
                             -->
                   المستخدمين


                        </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>


                      
              

                    </v-toolbar>
                </template>


                <!-- <template v-slot:bottom>
                    
                   <v-pagination v-model="page" prev-icon="mdi-menu-right" next-icon="mdi-menu-left" circle="" :length="pageCount">
      </v-pagination>
                </template> -->
                <template v-slot:item.role="{ item }">

                    <span v-if="item.role_id==1">عميل</span>
                    <span v-if="item.role_id==2">تاجر</span>

                </template>



                <template v-slot:item.status="{ item }">






<span>{{item.status.status_name_ar}}</span>



                    <!-- <span v-if="item.user.status.id==1">مفعل</span>
                    <span v-else>متوقف</span> -->
                </template>







                <template v-slot:item.edit="{ item }">

                    <!-- <v-btn icon="" @click="editItem(item)">
                        <v-icon>fas fa-edit</v-icon>
                    </v-btn> -->

                      <v-btn    icon="" @click="delete_item(item.id)" >
            <v-icon color="red">fas fa-trash</v-icon>
          </v-btn>

                </template>



            </v-data-table>

        </v-row>
        <v-row>


            <v-pagination v-model="page" prev-icon="mdi-menu-right" next-icon="mdi-menu-left" circle=""
                :length="pageCount">
            </v-pagination>
        </v-row>

     
    </v-layout>

</template>


<script>
    const axios = require('axios');
    export default {
        data: () => ({

            owner_status: '',
            owners_status: [{
                    id: 0,
                    name: 'الكل'
                }, {
                    id: 1,
                    name: 'المفعلين'
                },

                {
                    id: 2,
                    name: 'غير مفعل'
                },

                {
                    id: 24,
                    name: 'معلق'
                },




            ],

            verfy_Dailog: false,
            verfy_code: '',
            active_form: false,
            signup_user_id: '',
            today: '2019-01-08',
            selectedType: 'month',
            verfyRules: [
                (v) => !!v || 'يجب ادخال كود التفعيل',
                (v) => (v.length == 6) || ' يجب ان يكون كود التفعيل 6 ارقام',
                (v) => /^\d+$/.test(v) || 'ارقام فقط'

            ],


            page: 1,
            pageCount: 0,
            current_page: 1,
            last_page: 0,

            //loading: false,
            user_info: {},
            show1: false,
            show2: false,
            category: [],
            //   owner:{},

            owners: {
                name: "",
                email: "",
                password: "",
                phone: "",
                company_name: "",
                category_id: ""


            },
            editedItem: {

                user: {
                    created_at: "",
                    full_name: "",
                    id: "",
                    mobile_token: "",
                    remember_token: "",
                    role_id: "",
                    password_confirmation: "",
                    status_id: "",
                    updated_at: "",
                },

                owner_category: [{
                    id: "",
                    category_id: "",
                    owner_id: "",
                    status_id: "",
                    created_at: "",

                }],

                owner_description: "",
                owner_email: "",
                password: "",
                user_phone: "",
                owner_barnd_name: "",
                owner_phone: "",
                status_id: "",
                owner_type_id: "",
                province_id: "",
                category_id: "",
                long: "66",
                lat: "32",

            },

            calander_dialog: false,
            err_show: false,
            error: '',
            search: '',
            urL: 'https://api.ahjez-ely.com',
            dialog: false,
            items: [],
            images: [],
            passwordRules: [
                v => !!v || 'يجب ادخال الباسورد',
                (v) => v.length >= 8 || 'يجب ان لايقل الباسورد عن ٨ احرف او ارقام',
                (v) => /^.*(?=.{3,})(?=.*[a-zA-Z])/.test(v) || 'يجب ان يحتوي على حروف وارقام'
            ],
            passwordConfirmationRules: [
                v => !!v || 'يجب ادخال الباسورد',
                (v) => v.length >= 8 || 'يجب ان لايقل الباسورد عن ٨ احرف او ارقام',
                (v) => /^.*(?=.{3,})(?=.*[a-zA-Z])/.test(v) || 'يجب ان يحتوي على حروف وارقام',

            ],



            nameRules: [
                (v) => !!v || 'يجب ادخال الاسم',

            ],





            phoneRules: [
                (v) => !!v || 'يجب ادخال رقم الهاتف',
                (v) => v.length == 11 || 'يجب ادخال رقم هاتف صحيح',

            ],





            emailRules: [



                (v) => (v.length < 1 || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v)) ||
                'ألايميل غير صحيح',


            ],



            PasswordRules: [
                (v) => !!v || 'يجب ادخال الباسورد',
                v => v.length > 3 || ' يجب ان لا يقل عن 4',

            ],

            imgname: '',
            ownres: [],
            filters: {
                owner_phone: "",
                status_id: "",
                owner_cat:""
            },

            item_selected: {},
            loding_accept: false,
            loding_cancel: false,
            headers: [{
                    text: 'رقم التعريف',
                    align: 'center',
                    sortable: false,
                    value: 'id',
                },
                {
                    text: 'الاسم',
                    align: 'center',
                    sortable: false,
                    value: 'full_name',
                },
                // {
                //     text: 'اسم الشركة',
                //     align: 'center',
                //     sortable: false,
                //     value: 'owner_barnd_name',
                // },
                {
                    text: 'رقم الهاتف',
                    align: 'center',
                    sortable: false,
                    value: 'user_phone',
                },

                // { text: 'الوصف', value: 'disc' },
                // {
                //     text: 'البريد الالكتروني',
                //     align: 'center',
                //     sortable: false,
                //     value: 'owner_email',
                // },


                {
                    text: 'نوع الحساب',
                    value: 'role',
                    sortable: false
                },



                {
                    text: ' الحالة',
                    value: 'status',
                    align: 'center',
                },
                // {
                //     text: ' ',
                //     value: 'action',
                //     align: 'center',
                // },

                {
                    text: '',
                    value: 'edit',
                    sortable: false
                },


            ],
            editedIndex: -1,

            owner_phonex: '',

            valid: false,
            // page: 1,
            // pageCount: 0,
            // current_page: 0,
            // last_page: 0,
            loading: false


        }),



        methods: {
Search_phone()
{
    this.filters.owner_phone=this.owner_phonex;

},


      
 getDateOnly(date) {
                if (date != null) {
                    return date.split("T")[0];
                } else {
                    return "--------"
                }

            },


delete_item(id) {


        this.user_info = this.$cookies.get('admin_info');
        const Swal = require('sweetalert2')


        Swal.fire({
          title: "هل انت متاكد من الحذف ؟",

          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'نعم',
          cancelButtonText: 'خروج'

        }).then((result) => {
          if (result.value) {
            var url = "/operation/DeletedUser/" + id;
            axios({
              method: 'delete',
              url: url,
              headers: {}

            }).then(response => {
              response
              
              this.Search();


            }).catch(error => {
              error
              // this.$swal('خطاء', "خطاء بالاتصال", 'error')
            }).finally(d => {
              d,
              Swal.fire(
                'تم الحذف بنجاح',
                '',
                'success'
              )
              this.Search();
            });



          }
        })



      },

            Search() {
                this.loading = true;


             //   var owner_phone = this.filters.owner_phone;
                // if (this.filters.owner_phone !== "") {
                //     owner_phone= "964" + parseInt(this.filters.owner_phone);
                // } else {
                //      owner_phone = '';
                // }



                this.loading = true;
                   this.$http({
                    method: 'get',
                    url:'/operation/getAllOwnersUsers?page='+this.current_page,
                    headers: {

                    }

                }).then(response => {
                    this.loading = false;
                    this.ownres = response.data.data.data;


                    this.last_page = response.data.data.last_page;
                    this.pageCount = response.data.data.last_page;
                   


                }).catch(error => {
                    error
                    //this.$swal('خطاء', "خطاء بالاتصال", 'error')
                }).finally(s => {
                    s,
                    this.loading = false;
                });
            },
            add_owner() {
                this.editedItem = {

                    user: {
                        created_at: "",
                        full_name: "",
                        id: "",
                        mobile_token: "",
                        remember_token: "",
                        role_id: "",
                        status_id: "",
                        updated_at: "",
                    },

                    owner_category: [{
                        id: "",
                        category_id: "",
                        owner_id: "",
                        status_id: "",
                        created_at: "",

                    }],

                    owner_description: "",
                    owner_email: "",
                    password: "",
                    user_phone: "",
                    owner_barnd_name: "",
                    owner_phone: "",
                    status_id: "",
                    owner_type_id: "",
                    province_id: "",
                    category_id: "",
                    long: "66",
                    lat: "32",

                };
            },

            editItem(item) {
                this.editedIndex = 1;
                this.editedItem = Object.assign({}, item);




                this.dialog = true;

            },

           
        

            

            close() {
                this.dialog = false;
                this.Search();
            },


        
            changeStatus(item) {






                const Swal = require('sweetalert2');



                Swal.fire({
                    title: "هل انت متاكد من الامر ؟",

                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'نعم',
                    // cancelButtonText: 'خروج'

                }).then((result) => {
                    if (result.value) {


                        var data = {


                            owner_id: item.id,
                            //  status_id: item.user.status_id == 1 ? 2 : 1
                            status_id: item.user.status.id


                        };
                        this.loading = true;
                        // this.description=JSON.stringify(data);
                        this.$http({
                                method: 'post',
                                url: "operation/OwnerChangeStatus",
                                data: data,
                                headers: {

                                }
                            })
                            .then(response => {
                                response,
                                this.dialog = false;

                                item.user.status.id = item.user.status.id == 1 ? 2 : 1;

                                item.user.status.id == 1 ? this.$swal('', "   تم ايقاف التاجر", 'success') :
                                this.$swal('', "   تم  تفعيل التاجر",
                                        'success');

                                this.Search();





                            })
                            .catch(() => {


                                this.$swal('', " حدث خطاء", 'error')
                            }).finally(() => {

                                this.loading = false;
                            });




                    }
                })



            },
           
            deleteItem(item) {
                //  const index = this.items.indexOf(item)
                this.cancelReservation(item);




            },



          
            getMoreitems() {

                if (this.current_page <= this.last_page) {
                    this.current_page = this.page;
                    this.Search();
                }



            },


          
            getInfo() {


                this.user_info = this.$cookies.get('admin_details');
            }

        },

        mounted() {
            this.getInfo();

            this.Search();
          

        },


        computed: {

            selected: function () {

                return this.getMoreitems();
            }


        },


        watch: {
            selected: 'search by sub_cat_id',
        },

    }
</script>